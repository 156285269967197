

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../redux/actions/cartActions';
import { Form } from 'react-bootstrap';
import Footer from './Footer';
import {  API_BASE_URL_IMAGE} from '../constants/ApiConfig';

const Card = ({ dish }) => {
  const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
 
  const handleIncrease = () => {
    setQuantity((prev) => prev + 1);
  };

  const handleDecrease = () => {
    setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
  };

  const handleAddToCart = () => {
    dispatch(addToCart(dish, quantity));
  };

  return (
    <>
    <div className=" bg-white shadow-lg rounded-lg  w-full sm:w-1/2 lg:w-1/4 m-2">
      <div className="card-image-placeholder rounded-lg bg-gray-200 h-48 mb-4 flex items-center justify-center">
        <img src={`${API_BASE_URL_IMAGE}/${dish.image}`} alt={dish.name} className="object-cover w-full h-full rounded-t-lg" />
      </div>
      <div className='ml-4 h-72'>
      <h2 className="text-base font-semibold  ">{dish.name}</h2>
      <p className="text-green-500 text-xl font-bold ">{dish.price} DH</p>
      <div className="quantity-control flex items-center justify-between mb-{-30px}" style={{ marginTop: "-3px" }}>
        <Form.Group controlId="quantity" className="mt-2">
          <div className="d-flex align-items-center">
            <button
             onClick={handleDecrease}
            className="bg-gray-200 text-gray-700 rounded-md w-8 h-9 p-2"
             
             >
              -
            </button>
            <span className="mx-2">{quantity}</span>

            

            <button
            onClick={handleIncrease} 
            className="bg-gray-200 text-gray-700 rounded-md w-8 p-2 h-9"
            >
              +
            </button>
          </div>
        </Form.Group>
      </div>
      <p className="text-gray-600 w-[70%] max-h-15 ">{dish.description}</p>

      <button className="w-36 text-[13px] mt-2 btn btn-success" onClick={handleAddToCart}>ADD TO PANIER</button>
      </div>
    </div>
    <Footer />
    </>

  );
};

export default Card;
