import React from 'react';
import { Badge } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const cartItems = useSelector((state) => state.cart.items);
  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  return (
    <nav className="navbar bg-green-700" >
      <h1>Delicious Dishes</h1>
      
{totalItems > 0 ? (
  <Link to="/cart">
    <Badge count={totalItems} offset={[0, 5]} className='text-green-950 b' style={{backgroundColor:"white" , color:"green"}}>
      <ShoppingCartOutlined  style={{color:"white" , fontSize:"25px"}}/>
    </Badge>
  </Link>
) : (
  <Badge count={totalItems} offset={[0, 5]} className='text-green-950 b' style={{backgroundColor:"white" , color:"green"}}>
    <ShoppingCartOutlined  style={{color:"white" , fontSize:"25px"}}/>
  </Badge>
)}

    </nav>
  );
};

export default Navbar;