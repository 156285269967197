import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * The footer component
 * @returns {JSX.Element} The footer component
 */
const Footer = () => {
  const cartItems = useSelector((state) => state.cart.items);

  // Calculate total price and total number of items
  const totalPrice = cartItems.reduce((acc, item) => acc + item.product.price * item.quantity, 0);
  const totalItems = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  /**
   * The JSX for the footer component
   */
  return (
    <footer className="fixed-bottom bg-light text-center py-2">
      {/* Display the total price and total number of items */}
      <div style={{ display: 'inline-block' }}>
        Total: <span style={{ color: '#35a62c' }}>{totalPrice.toFixed(2)} Dh</span> 
      </div>

      {/* Display the checkout button if there are items in the cart */}
      {totalItems > 0 && (
        <Link
          to="/cart"
          className="btn btn-success d-inline-block  w-32 h-9 ml-[10px] text-white p-[8px] font-bold text-[13px]"
        >
          Checkout
        </Link>
      )}
    </footer>
  );
};

export default Footer;
