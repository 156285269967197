import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MyNavbar from './components/Navbar';

import Panier from './components/Panier'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import CardList from './components/CardList';
import './App.css';
import ThankYou from './components/ThankYou';


const App = () => {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <MyNavbar />
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={<CardList />} />
            <Route path="/cart" element={<Panier />} />
            <Route path="/thank-you" element={<ThankYou />} />

          </Routes>
        </main>
        </div>
    </Router>
  );
};

export default App;


