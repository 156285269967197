import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faTruck } from '@fortawesome/free-solid-svg-icons'; // Import specific icon

import 'leaflet/dist/leaflet.css';
const ThankYou = () => {

  return (
    <div className="thank-you-container text-center p-4" style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      minHeight: '100vh' // Ensures the container takes full viewport height
  }}>
    <FontAwesomeIcon icon={faTruck} className="text-[100px] mb-4" style={{color: 'green'}} /> {/* Use FontAwesomeIcon with imported icon */}
    <h1 className="text-5xl font-bold mb-4">Thank you for your order!</h1>
    <p>Your order is on its way. We appreciate your business.</p>
    <p>Make sure your phone is active to confirm the order.</p>
  </div>



  
  );
};

export default ThankYou;
