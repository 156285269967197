import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from './../api/axiosConfig';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const Panier = () => {
  const cartItems = useSelector(state => state.cart.items);
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState([34.241, -6.571]); // Default location
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [addressInput, setAddressInput] = useState('');
  const [client, setClient] = useState('');
  const [telephone, setTelephone] = useState('');
  const navigate = useNavigate();

  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  });

  const totalPrice = cartItems.reduce((acc, item) => acc + item.product.price * item.quantity, 0);


  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setLocation([latitude, longitude]);
        setLatitude(latitude);
        setLongitude(longitude);
        // Convert coordinates to address
        convertCoordinatesToAddress(latitude, longitude);
      }, (error) => {
        console.error(error);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const convertCoordinatesToAddress = async (lat, lng) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`);
      const address = response.data.display_name;
      setAddressInput(address);
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const convertAddressToCoordinates = async () => {
    if (!addressInput.trim()) {
      alert("Please enter an address.");
      return;
    }

    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(addressInput)}`);
      const { lat, lon } = response.data[0] || {};
      if (lat && lon) {
        setLatitude(lat);
        setLongitude(lon);
        setLocation([lat, lon]);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Address not found. Please try again.!",
        });
        setLatitude('');
        setLongitude('');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Error fetching coordinates. Please try again.!",
      });
    }
  };

  const handlePlaceOrder = async () => {
    if (!client || !telephone || !addressInput.trim() || !latitude || !longitude) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill out all the details and make sure you select a valid location and click the button to get the location.!",
      });
      
      return;
    }

    const orderData = {
      client,
      telephone,
      price: totalPrice,
      latitude,
      longitude,
      products: cartItems.map(item => ({
        id: item.product.id,
        quantity: item.quantity,
        price_total: item.product.price * item.quantity
      })),
    };
    try {
      const response = await axios.post('/orders', orderData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Order sent successfully:', response.data);
      navigate('/thank-you');

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `'Error sending order:', ${error}`,
      });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl w-full p-6 sm:p-8">
    {/* Delivery Details Section */}
    <div className="p-4 sm:p-6">
      <h1 className="text-xl sm:text-2xl font-bold mb-2">Delivery Details</h1>
      <p className="text-gray-600 mb-4">Enter your information to complete your order.</p>

      <div className="mb-4">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name</label>
        <input 
          type="text" 
          id="name" 
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 sm:text-sm"
          value={client}
          onChange={(e) => setClient(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone Number</label>
        <input 
          type="number"
          max={10} 
          id="phone" 
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 sm:text-sm"
          value={telephone}
          onChange={(e) => setTelephone(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
        <div className="relative">
          <input 
            type="text" 
            id="address" 
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 sm:text-sm"
            value={addressInput}
            onChange={(e) => setAddressInput(e.target.value)}
          />
          <button 
            className="absolute right-2 top-2"
            onClick={() => setShowModal(true)}
          >
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11V5H9v2H7v2h2v2h2v-2h2V7h-2z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <button 
          className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
          onClick={convertAddressToCoordinates}
        >
          Get Coordinates
        </button>
      </div>

      {/* Modal for Map */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-4 sm:p-8 rounded-md shadow-lg w-11/12 sm:w-10/12 md:w-8/12 lg:w-7/12">
            <h2 className="text-xl sm:text-2xl font-bold mb-2">Choose Your Location</h2>
            <p className='mb-4 text-sm text-gray-500'>Choose your location or get location automatically and when finished click "Close" and click "Get Coordinates"</p>
            <div className="relative h-64 sm:h-80">
              <MapContainer center={location} zoom={11} style={{ height: "100%", width: "100%" }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={location} />
                <UseMapEvents setLocation={setLocation} setLatitude={setLatitude} setLongitude={setLongitude} convertCoordinatesToAddress={convertCoordinatesToAddress} />
              </MapContainer>
            </div>
            <button 
              className="mt-4 bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
              onClick={handleGetCurrentLocation}
            >
              Get Location Automatically
            </button>
            <button 
              className="mt-4 bg-red-600 text-white py-2 ml-3 px-4 rounded-md hover:bg-red-700"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>

    {/* Order Summary Section */}
    <div className="p-4 sm:p-6">
      <h1 className="text-xl sm:text-2xl font-bold mb-2">Order Summary</h1>
      <p className="text-gray-600 mb-6">Review your order details before placing your order.</p>

      <ul className="space-y-4">
        {cartItems.map(item => (
          <li key={item.product.id} className="flex justify-between">
            <span>{item.product.name} x {item.quantity}</span>
            <span>{(item.product.price * item.quantity).toFixed(2)}DH</span>
          </li>
        ))}
        <li className="flex justify-between">
          <span>Shipping</span>
          <span>10.00DH</span>
        </li>
      </ul>

      <hr className="my-4"/>

      <div className="flex justify-between font-bold text-lg">
        <span>Total</span>
        <span>{(totalPrice + 10.00).toFixed(2)}DH</span>
      </div>

      <button 
        className="mt-6 w-full bg-green-600 text-white py-2 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
        onClick={handlePlaceOrder}
      >
        Place Order
      </button>
    </div>
  </div>
</div>

  );
};

const UseMapEvents = ({ setLocation, setLatitude, setLongitude, convertCoordinatesToAddress }) => {
  useMapEvents({
    click(event) {
      const { lat, lng } = event.latlng;
      setLocation([lat, lng]);
      setLatitude(lat); // Set the latitude
      setLongitude(lng); // Set the longitude
      convertCoordinatesToAddress(lat, lng); // Update address when location is set
    },
  });

  return null;
};

export default Panier;




