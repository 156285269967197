import React, { useEffect, useState } from 'react';
import Card from './Card';
import { getProducts } from '../request/request';

/**
 * A component that displays a list of products as cards
 *
 * @returns {JSX.Element} The CardList component
 */
const CardList = () => {
  const [products, setProducts] = useState([]);

  // GET PRODUCTS
  useEffect(() => {
    /**
     * Fetches the products from the API and sets them in the state
     * @param {Function} getProducts The function to fetch the products
     */
    getProducts()
      .then((res) => {
        setProducts(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card-container flex flex-wrap justify-center mb-12">
      {/* Renders a card for each product in the state */}
      {products.map((product) => (
        <Card key={product.id} dish={product} />
      ))}
    </div>
  );
};


export default CardList;
